// Typography
//
// Font, line-height, and color for body text, headings, and more.
@font-face {
	font-family: 'SofiaPro';
	src: url('../fonts/SofiaPro-Bold.woff2') format('woff2'),
	url('../fonts/SofiaPro-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SofiaPro';
	src: url('../fonts/SofiaPro-Light.woff2') format('woff2'),
	url('../fonts/SofiaPro-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SofiaPro';
	src: url('../fonts/SofiaPro-Medium.woff2') format('woff2'),
	url('../fonts/SofiaPro-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'SofiaPro';
	src: url('../fonts/SofiaPro-SemiBold.woff2') format('woff2'),
	url('../fonts/SofiaPro-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: 'SofiaPro';
	src: url('../fonts/SofiaPro-Regular.woff2') format('woff2'),
	url('../fonts/SofiaPro-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}


$font_family_primary: 'SofiaPro';

$font_size-body: 0.875rem;

$font-weight-500: 500;
$font-weight-600: 600;

$font-sises: (
		'11':11px,
		'12':12px,
		'14':14px,
		'15':15px,
		'16':16px,
		'18':18px,
		'20':20px,
		'24':24px,
		'30':30px,
		'34':34px,
		'38':38px,
		'40':40px,
		'48':48px,
		'56':56px,
		'60':60px,
		'80':80px,
		'100':100px,
		'140':140px,
);
$line-heights: (
		1:1,
		12:1.2,
		13:1.3,
		14:1.4,
		15:1.5,
		16:1.6,
		175:1.75,
		1875:1.875,
		2:2,
		213:2.13
);

$opacity: (
		0:0,
		1:0.1,
		2:0.2,
		3:0.3,
		4:0.4,
		5:0.5,
		6:0.6,
		7:0.7,
		8:0.8,
		9:0.9,
		10:1,
);
$z-index: (
		1:1,
		2:2,
		3:3,
		4:4,
		5:5,
		6:6,
		7:7,
		8:8,
		9:9,
		10:10,
);

$letter-spacings: (
		'0':0,
		'05':0.5px,
		'26':2.6px
);

$heading-color: #000;
$background-color-1: #f5f1ed;
$background-color-2: #f6f6f6;
$background-color-3: #f7f7f7;
$background-color-4: #f2f2f2;
$background-color-5: #e8e8e8;

$grid-number-columns: (
		1:1,
		2:2,
		3:3,
		4:4,
		5:5,
		6:6,
		7:7,
		8:8,
		9:9,
		10:10,
		11:11,
		12:12,
		13:13,
		14:14,
		15:15,
		16:16,
		17:17,
		18:18,
		19:19,
		20:20,
);
$grid-number-rows: (
		1:1,
		2:2,
		3:3,
		4:4,
		5:5,
		6:6,
		7:7,
		8:8,
		9:9,
		10:10,
		11:11,
		12:12,
		13:13,
		14:14,
		15:15,
		16:16,
		17:17,
		18:18,
		19:19,
		20:20,
		21:21,
);