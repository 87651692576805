.image-marker {
	position: relative;

	.image-pointer {
		position: absolute;
		bottom: auto;
		right: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		padding: 5px;
		transition: all 0.2s;

		.pointer-icon {
			font-size: 10px;
			width: 26px;
			height: 26px;
			color: $white;
			background-color: $primary;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			i {
				transition: all 0.5s;
			}

		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.26);

			.pointer-icon i {
				transform: rotate(360deg);
			}
		}

	}


	.tooltip {
		.arrow {
			&::before {
				display: none;
			}
		}

		&.show {
			opacity: 1;
		}

		.tooltip-inner {
			background-color: $white;
			color: $primary;
			max-width: none;
			text-align: left;
			padding: 16px;

			h5 {
				margin-bottom: 5px;
			}

			p {
				margin-bottom: 0;
				line-height: 1;
			}
		}
	}

	.image-description {
		left: 64px;
		bottom: 33px;

	}
}